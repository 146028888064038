import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

export default function Products() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 6
      ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              mainImageUrl {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className="product section bd-container" id="products">
      <h1 className="product__title">Products</h1>

      <div className="product__container bd-grid">
        {data.allMarkdownRemark.edges.map(({ node }, i) => (
          <Link to={node.frontmatter.path} key={i}>
            <div className="product__content">
              <Img
                fluid={node.frontmatter.mainImageUrl.childImageSharp.fluid}
                className="product__img"
              />
            </div>
          </Link>
        ))}
      </div>

      <div className="product__button">
        <Link to="products" className="button">
          View More
        </Link>
      </div>
    </section>
  )
}
