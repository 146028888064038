import React from "react"
import { Link } from "gatsby"
import Img from "../images/about.webp"

const About = () => (
  <section className="about section bd-container" id="about">
    <div className="about__container bd-grid">
      <img src={Img} alt="About" className="about__img" />
      <div className="about__data">
        <h1 className="about__subtitle">About</h1>
        <p className="about__description">
          PT. Whrilindo Indonesia Perkasa is a Turbine Ventilator and Plywood
          Distributor which was established in 2019 and based in Batam,
          Indonesia. Our vision is to be customer's preferred partner and our
          mission is to provide the best quality product and services by
          prioritizing customer satisfaction.
        </p>
        <Link to="about" className="button" aria-label="read-more">
          Read More
        </Link>
      </div>
    </div>
  </section>
)

export default About
