import * as React from "react"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Header from "../components/header"
import About from "../components/about"
import Products from "../components/products"
import Contact from "../components/contact"
import Footer from "../components/footer"
import ogImage from "../images/og-image.webp"

const IndexPage = () => {
  return (
    <>
      <Seo title="PT. Whrilindo Indonesia Perkasa" image={ogImage} />
      <Navbar />
      <Header />
      <About />
      <Products />
      <Contact />
      <Footer />
    </>
  )
}

export default IndexPage
